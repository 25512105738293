<template>
  <div class="container">
    <h1 class="display-3 fw-bold text-center py-5">{{ productData.title }}</h1>
    <div class="card mb-3 bg-light shadow mb-5">
      <div class="row g-0 card-content">
        <div class="col-12 text-center text-sm-end col-lg-7 order-lg-2">
          <Carousel :code="productData.code" />
        </div>
        <div class="col-12 text-sm-end col-lg-5" dir="ltr">
          <div class="card-body text-start d-flex flex-column">
            <h5 class="card-title fs-1 fw-bolder d-flex align-items-center">
              <span class="text-muted fw-lighter">{{ productData.code }}</span>
              <span
                class="badge text-dark fs-1 fw-bolder ms-auto"
                v-if="productData.w"
                >{{ productData.w }}
                <img src="@/assets/images/watt_1@4x.png" width="50" alt="watt"
              /></span>
            </h5>
            <table class="table table-hover">
              <tbody>
                <tr class="table-secondary" v-if="productData.v">
                  <td>Voltage (V)</td>
                  <td>{{ productData.v }}</td>
                </tr>
                <tr class="table-light" v-if="productData.freq">
                  <td>Frequency (Hz)</td>
                  <td>{{ productData.freq }}</td>
                </tr>
                <tr class="table-secondary" v-if="productData.lu">
                  <td>Luminous Flux (Lm)</td>
                  <td>{{ productData.lu }}</td>
                </tr>
                <tr class="table-light" v-if="productData.colortemp">
                  <td>Color Temperature</td>
                  <td>{{ productData.colortemp }}</td>
                </tr>
                <tr class="table-secondary" v-if="productData.ip">
                  <td>IP</td>
                  <td>{{ productData.ip }}</td>
                </tr>
                <tr class="table-light" v-if="productData.pf">
                  <td>Power Factor (PF)</td>
                  <td>&#8805;{{ productData.pf }}</td>
                </tr>
                <tr class="table-secondary" v-if="productData.life">
                  <td>Life Time (H)</td>
                  <td>{{ productData.life }}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end">
              <p dir="rtl" class="fs-5 m-0 text-end" v-if="productData.protect">
                * الكشاف مزود بدارة حماية ضد التيار المرتفع
              </p>
            </div>
            <div class="d-flex mt-4">
              <span
                class="badge bg-dark fs-4 me-4"
                v-if="productData.feature"
                >{{ productData.feature }}</span
              >
              <button class="btn btn-sm btn-dark ms-auto" @click="createPDF">
                <i class="fa fa-download me-2" aria-hidden="true"></i>تحميل
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  components: { Carousel },
  data() {
    return {
      code: this.$route.params.code
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // console.log({ to, from, next });
  //   window.scroll(0,0)
  //   next();
  // },

  methods: {
    createPDF() {
      let prod = this.productData;
      let bodyContent = [
        ["Title", prod.title],
        ["Family", prod.family],
        ["Code", prod.code],
        prod.w ? ["Watt (W)", prod.w] : [],
        prod.v ? ["Voltage (V)", prod.v] : [],
        prod.freq ? ["Frequency (Hz)", prod.freq] : [],
        prod.lu ? ["Luminous Flux (Lm)", prod.lu] : [],
        prod.colortemp ? ["Color Temperature", prod.colortemp] : [],
        prod.ip ? ["IP", prod.ip] : [],
        prod.pf
          ? ["Power Factor (PF)", "Greater-Than or Equal To " + prod.pf]
          : [],
        prod.life ? ["Life Time (H)", prod.life] : [],
        prod.feature ? ["Specifications", prod.feature] : []
      ];
      let bodyResult = bodyContent.filter(el => el.length > 0);
      // console.log(bodyContent.filter(el => el.length > 0));

      var doc = new jsPDF();
      // doc.setFontSize(40);
      // doc.text(35, 25, "Paranyan loves jsPDF");
      var finalY = doc.lastAutoTable.finalY || 10;
      doc.text("Sabbagh® for Electrical Industries", 14, finalY + 15);
      finalY += 15;

      doc.text(this.productData.code + " Datasheet", 14, finalY + 15);
      doc.autoTable({
        startY: finalY + 20,
        head: [["Key", "Value"]],
        body: bodyResult
      });
      // finalY = doc.lastAutoTable.finalY;
      // doc.text("From HTML with CSS", 14, finalY + 15);
      doc.save(this.productData.code + ".pdf");
    }
  },

  computed: {
    productData() {
      return this.$store.getters.getData.filter(a => a.code == this.code)[0];
    }
  }
};
</script>

<style scoped>
.card-content {
  min-height: 25em;
}
</style>
