<template>
  <vueper-slides
    fade
    autoplay
    :touchable="true"
    :fixed-height="true"
    :breakpoints="breakpoints"
  >
    <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image">
      <template v-slot:content>
        <a :href="slide.image" class="img-link"></a>
      </template>
    </vueper-slide>
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  props: ["code"],
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      breakpoints: {
        900: {
          slideRatio: 1 / 3
        },
        600: {
          slideRatio: 1 / 2,
          bulletsOutside: true
        }
      },
      slides: []
    };
  },
  mounted() {
    const allImages = this.$store.state.imagesPath;
    const selectedImages = allImages.filter(e => e.includes(`${this.code}/`));
    // console.log(selectedImages);
    if (selectedImages.length > 0) {
      for (let i = 0; i < selectedImages.length; i++) {
        const e = selectedImages[i];
        this.slides.push({
          image: require(`@/assets/images/productsImages/${e}` || "")
        });
      }
    } else {
      this.slides.push({
        image: require(`@/assets/images/notFound.jpg` || "")
      });
    }
    // console.log(this.slides);
  },
  // methods() {}
};
</script>

<style>
.vueperslides--fixed-height {
  height: 100%;
}

.vueperslide,
.vueperslide__image {
  background-size: contain !important;
  background-repeat: no-repeat;
}

@media (max-width: 992px) {
  .vueperslides--fixed-height {
    height: 350px;
  }
}
.vueperslides__arrow svg {
  stroke-width: 1.5;
  color: #545454;
}
.vueperslides__arrow svg:hover {
  stroke-width: 2;
  color: #eb9300;
}

.img-link {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
