<template>
  <div>
    <header class="product-header">
      <div class="container">
        <h2>كشافات الشارع</h2>
        <!-- <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio
          numquam recusandae maxime, blanditiis, ipsa aut quibusdam voluptatibus
          obcaecati itaque dolore hic eligendi ex praesentium fugit voluptatem.
          Delectus necessitatibus sapiente consequatur!
        </p> -->
        <div class="inside-index">
          <a href="#Spider" class="btn">Spider</a>
          <a href="#Tennis" class="btn">Tennis</a>
          <a href="#Tree" class="btn">Tree</a>
        </div>
      </div>
    </header>

    <div id="Spider" class="container" dir="ltr">
      <h2 class="display-2 text-center py-2"><u>Spider</u></h2>
      <div class="row">
        <ProductCard
          v-for="product in spider"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
    <div id="Tennis" class="container" dir="ltr">
      <h2 class="display-2 text-center py-2"><u>Tennis</u></h2>
      <div class="row">
        <ProductCard
          v-for="product in tennis"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
    <div id="Tree" class="container" dir="ltr">
      <h2 class="display-2 text-center py-2"><u>Tree</u></h2>
      <div class="row">
        <ProductCard
          v-for="product in tree"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
export default {
  components: {
    ProductCard
  },
  data() {
    return {
      pData: this.$store.getters.getData
    };
  },
  computed: {
    spider() {
      return this.pData.filter(a => a.family == "spider" && a.type == "s");
    },
    tennis() {
      return this.pData.filter(a => a.family == "tennis" && a.type == "s");
    },
    tree() {
      return this.pData.filter(a => a.family == "tree" && a.type == "s");
    }
  }
};
</script>
