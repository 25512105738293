<template>
  <div class="container">
    <header class="product-header">
      <div class="container">
        <h2>
          <img
            src="@/assets/images/marks/marks-04.png"
            class="img-fluid"
            alt="TWilight"
          />
        </h2>
        <!-- <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio
          numquam recusandae maxime, blanditiis, ipsa aut quibusdam voluptatibus
          obcaecati itaque dolore hic eligendi ex praesentium fugit voluptatem.
          Delectus necessitatibus sapiente consequatur!
        </p> -->
        <!-- <div class="inside-index">
          <a href="#" class="btn">-</a>
        </div> -->
      </div>
    </header>

    <div class="row">
      <ProductCard
        v-for="product in twilight"
        :key="product.id"
        :productData="product"
      />
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
export default {
  components: {
    ProductCard
  },
  computed: {
    twilight() {
      return this.$store.getters.getData.filter(a => a.type == "t");
    }
  }
};
</script>

<style>
h2 .img-fluid {
  width: 5em;
}
</style>
